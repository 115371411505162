import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/Kinetik/Navbar";
import Footer from "../components/Kinetik/Footer";
import PageBanner from '../components/Common/PageBanner';
import Cloudbild from "../assets/images/kinetik/service-cloud.jpg";
import ContactForm3 from "../components/Kinetik/ContactForm3";

const Shop = () => (
    <Layout>
        <SEO title="Produkte" /> 

        <Navbar />

		<PageBanner pageTitle="Produkte" /> 

		<div class="container">
		<div class="row flex-row">
		
			<div class="col-lg-6 col-md-12">
				<img src={Cloudbild}/>
			</div>
			
			<div class="col-lg-6 col-md-12 padding-top-30 padding-bottom-30">
				<b>ibright GECKO</b> <p>ibright GECKO ist ein Online-Design System, das in jedem modernen Web-Browser auf jedem modernen Gerät läuft, z.B. Smartphones, Tablets und Desktop-Computer. Um seine Funktionalität einzuordnen, stellt man sich am besten ein professionelles Desktop-Vektorgrafikprogramm vor, das um ein Rechtemanagement-System erweitert wurde.

ibright GECKO ist bereits bei namhaften Kunden im Einsatz. Wenn Sie interesse haben, schreiben Sie uns und stellen uns Ihr Projekt vor.</p>
				<ContactForm3 />
			</div>		
						
			</div>
		</div>
		
        <Footer />
        
       
        
    </Layout>
)

export default Shop;